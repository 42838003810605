import React from 'react';
import PropTypes from 'prop-types';

import './SideBar.scss';

const SideBar = ({
                        title
                    }) => {
    return (
        <div className={`Sidebar`}>
            <div className="font-bold text-4xl">Spécialités</div>

            <div className="sidebar--specialites">
                <a href="/omnipratique" className="whitespace-no-wrap">Omnipratique</a>
                <a href="/pedodontie" className="whitespace-no-wrap">Pédodontie</a>
                <a href="/chirurgie-buccale" className="whitespace-no-wrap">Chirurgie Buccale</a>
                <a href="/endodontie" className="whitespace-no-wrap">Endodontie</a>
                <a href="/parodontie" className="whitespace-no-wrap">Parodontie</a>
                <a href="/orthodontie" className="whitespace-no-wrap">Orthodontie</a>
                <a href="/occlusodontie" className="whitespace-no-wrap">Occlusodontie</a>
                <a href="/protheses-dentaire" className="whitespace-no-wrap">Prothèses</a>
                <a href="/esthetique" className="whitespace-no-wrap">Esthétique</a>
            </div>
        </div>
    )
};

SideBar.propTypes = {
    title: PropTypes.string
};

export default SideBar


